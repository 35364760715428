
import { ParkingFacilityQueryModel } from '@/pages/sub-system/model/parking-facility-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post } from '@common-src/service/request';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { SubSystemType } from '@common-src/model/enum';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/facility`;

class ParkingFacilityService implements ICRUDQ<any, ParkingFacilityQueryModel> {
    async create(model: any): Promise<any> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string): Promise<any> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return res;
    }

    async update(model: any): Promise<any> {
        throw new Error('Not implement.');
    }

    async delete(model: any): Promise<any> {
        throw new Error('Not implement.');
    }

    async query(query?: ParkingFacilityQueryModel, page?: number, limit?: number): Promise<any> {
        let url = null;
        switch (query?.subSystemType) {
            case SubSystemType.FIRE:
                url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/facility/query`;
                break;
            case SubSystemType.CAMERA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/query`;
                break;
            case SubSystemType.ENVIROMENT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/facility/query`;
                break;
            case SubSystemType.ENERGY:
                url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/facility/query`;
                break;
            case SubSystemType.BA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/query`;
                break;
            case SubSystemType.CUSTOMER_FLOW:
                url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/facility/query`;
                break;
            case SubSystemType.ELEVATOR:
                url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/facility/query`;
                break;
            case SubSystemType.LIGHT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/query`;
                break;
            case SubSystemType.ACCESS_CONTROL:
                url = `${BIBIZ_BASE_REQUEST_PATH}/acs/facility/query`;
                break;
            case SubSystemType.COMPUTER_ROOM:
                url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/facility/query`;
                break;
            case SubSystemType.PARKING:
                url = `${URL_PATH}/query`;
                break;
            default:
                url = `${BIBIZ_BASE_REQUEST_PATH}/common/facility/query`;
                break;
        }
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return res;
    }

    async parkingFacilityDetail(id: string, subSystemName: SubSystemType): Promise<any> {
        let url = null;
        switch (subSystemName) {
            case SubSystemType.FIRE:
                url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/facility/${id}`;
                break;
            case SubSystemType.CAMERA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/${id}`;
                break;
            case SubSystemType.ENVIROMENT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/facility/${id}`;
                break;
            case SubSystemType.ENERGY:
                url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/facility/${id}`;
                break;
            case SubSystemType.BA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/${id}`;
                break;
            case SubSystemType.CUSTOMER_FLOW:
                url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/facility/${id}`;
                break;
            case SubSystemType.ELEVATOR:
                url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/facility/${id}`;
                break;
            case SubSystemType.LIGHT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/${id}`;
                break;
            case SubSystemType.ACCESS_CONTROL:
                url = `${BIBIZ_BASE_REQUEST_PATH}/acs/facility/${id}`;
                break;
            case SubSystemType.COMPUTER_ROOM:
                url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/facility/${id}`;
                break;
            case SubSystemType.PARKING:
                url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/facility/${id}`;
                break;
            default:
                url = `${BIBIZ_BASE_REQUEST_PATH}/common/facility/${id}`;
                break;
        }
        const res = await get(url);
        return res;
    }

    async getLocationTree(type: SubSystemType, hasAllGroup: boolean = false): Promise<Array<SimpleTreeModel>> {
        let url = null;
        switch (type) {
            case SubSystemType.FIRE:
                url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/facility/locationTree`;
                break;
            case SubSystemType.CAMERA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/locationTree`;
                break;
            case SubSystemType.ENVIROMENT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/facility/locationTree`;
                break;
            case SubSystemType.ENERGY:
                url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/facility/locationTree`;
                break;
            case SubSystemType.BA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/locationTree`;
                break;
            case SubSystemType.CUSTOMER_FLOW:
                url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/facility/locationTree`;
                break;
            case SubSystemType.ELEVATOR:
                url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/facility/locationTree`;
                break;
            case SubSystemType.LIGHT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/locationTree`;
                break;
            case SubSystemType.ACCESS_CONTROL:
                url = `${BIBIZ_BASE_REQUEST_PATH}/acs/facility/locationTree`;
                break;
            case SubSystemType.COMPUTER_ROOM:
                url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/facility/locationTree`;
                break;
            case SubSystemType.PARKING:
                url = `${URL_PATH}/locationTree`;
                break;
            default:
                url = `${BIBIZ_BASE_REQUEST_PATH}/common/facility/locationTree`;
                break;
        }
        let res = await get(url);
        if (hasAllGroup) {
            if (!res) {
                res = [];
            }
            res.unshift({
                id: '',
                name: '全部分组'
            });
        }

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getFacilityType(type: SubSystemType): Promise<any> {
        let url = null;
        switch (type) {
            case SubSystemType.FIRE:
                url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/facility/facilityTypes`;
                break;
            case SubSystemType.CAMERA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/facility/facilityTypes`;
                break;
            case SubSystemType.ENVIROMENT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/facility/facilityTypes`;
                break;
            case SubSystemType.ENERGY:
                url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/facility/facilityTypes`;
                break;
            case SubSystemType.BA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/facility/facilityTypes`;
                break;
            case SubSystemType.CUSTOMER_FLOW:
                url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/facility/facilityTypes`;
                break;
            case SubSystemType.ELEVATOR:
                url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/facility/facilityTypes`;
                break;
            case SubSystemType.LIGHT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/facility/facilityTypes`;
                break;
            case SubSystemType.ACCESS_CONTROL:
                url = `${BIBIZ_BASE_REQUEST_PATH}/acs/facility/facilityTypes`;
                break;
            case SubSystemType.COMPUTER_ROOM:
                url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/facility/facilityTypes`;
                break;
            case SubSystemType.PARKING:
                url = `${URL_PATH}/facilityTypes`;
                break;
            default:
                url = `${BIBIZ_BASE_REQUEST_PATH}/common/facility/facilityTypes`;
                break;
        }
        let res = await get(url);

        return _.map(res, item => {
            return {
                name: item.name,
                value: item.id
            };
        });
    }
}

export default new ParkingFacilityService();
